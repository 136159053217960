.liveSearchInputContainer {
  position: relative;

  .searchInput {
    width: 100%;
    height: 62px;
    padding: 10px;
    text-align: left;
    border: none;
    border-radius: 5px;

    &::placeholder {
      font-weight: 600;
      font-size: 16px;
      color: #637381;

      @media screen and (max-width: 480px) {
        color: #637381;
      }
    }

    &:focus {
      outline: none !important;
      border-color: rgb(255, 117, 23);
    }

    &:hover {
      outline: none !important;
      border-color: rgb(255, 117, 23);
    }

    @media screen and (max-width: 480px) {
      font-size: 16px;
      font-weight: 500;
      height: 56px;
    }
  }

  .searchInputNeedPlaceHolder {
    @extend .searchInput;
    border: 1px solid #d9d9d9;
    border-radius: 8px;

    &::placeholder {
      font-size: 16px;
      font-weight: 600;
      color: #637381;

      @media screen and (max-width: 480px) {
        color: #637381;
      }
    }
  }
  .responseDropDown {
    background-color: #ffffff;
    box-shadow: 0 0 3px #719ece;
    text-align: left;
    border-radius: 5px;
    position: absolute;
    width: 100%;

    z-index: 100;

    .responseItem {
      padding: 10px;
      cursor: pointer;
      color: #000000;

      &:hover {
        color: #000000;
        background-color: rgba(255, 117, 23, 0.08);
      }
    }
  }

  .open {
    visibility: visible;

    .full {
      height: max-content;
    }
    .overflowed {
      max-height: 40vh;
      overflow: auto;
    }
  }

  .close {
    visibility: hidden;
  }
}
