@import '../Media.scss';

.modalWrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  backdrop-filter: blur(3px);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 100;

  .modalWindow {
    padding: 20px;
    text-align: center;
    vertical-align: middle;
    background-color: #fffaf2;

    border-radius: 15px;
    transform: scale(0.5);
    transition: 0.4s all;
  }

  .modalWindow.active {
    transform: scale(1);
  }

  img {
    justify-content: end;
    width: 30px;
    height: 30px;
  }

  .modalContent {
    overflow-y: scroll;
    max-height: 600px;
    padding: 3em;
  }
}

.modalWrapper.active {
  opacity: 1;
  pointer-events: all;
}

.payButton {
  color: #ffffff;
  height: 2em;
  width: 4em;
  font-size: 27px;
  background: rgb(255, 117, 23) !important;
  border-radius: 15px;
  border: none;
}

@include desktop {
  .modalWrapper {
    .modalContent {
      max-height: 500px;
    }
  }
}

@include tablets {
  .modalWrapper {
    .modalContent {
      max-height: 400px;
    }
  }
}

@include tablets {
  .modalWrapper {
    .modalContent {
      max-height: 350px;
    }
  }
}
