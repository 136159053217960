.Seat {
  fill: #ffffff;
  position: relative;
  display: block;
  stroke: #d8e6ea;
  stroke-width: 3;
  stroke-miterlimit: 10;

  &:hover {
    fill: #d3d3d3;
    cursor: pointer;
  }
}

.divSeat {
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  text-align: center;
  border: 1px solid #e8e8e8;
  width: 32px;
  height: 32px;
  font-size: 14px;
  border-radius: 10px;

  &:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
}

.divSeatPeaked {
  align-items: center;
  justify-content: center;
  background-color: #ff7517;
  position: relative;
  align-content: center;
  color: #ffffff;
  border: 1px solid #e8e8e8;
  width: 32px;
  height: 32px;
  font-size: 14px;
  border-radius: 10px;
  &:hover {
    background-color: #d65f10;
    cursor: pointer;
  }
}

.Numbers {
  pointer-events: none;
  font-size: 25px;
}

.Seat {
  fill: #ffffff;
  position: relative;
  display: block;
  stroke: #d8e6ea;
  stroke-width: 3;
  stroke-miterlimit: 10;

  &:hover {
    fill: #d3d3d3;
    cursor: pointer;
  }
}

.SeatPeaked {
  fill: #add8e6;
  display: block;
  z-index: 100;
  stroke: #d8e6ea;
  stroke-width: 3;
  stroke-miterlimit: 10;
  &:hover {
    fill: rgb(238, 231, 231);
    cursor: pointer;
  }
}

.SeatTaken {
  fill: #808080;
  display: block;
  z-index: 100;
  stroke: #d8e6ea;
  stroke-width: 3;
  stroke-miterlimit: 10;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.tooltipScheme {
  display: initial;
  position: absolute;
  font-size: 15px;
  background: white;
  padding: 1px 10px;
  z-index: 5;
  height: max-content;
  width: max-content;
  line-height: 30px;
  margin: 0 auto;
  color: #000513;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #eee;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    width: 0;
    height: 0;
    margin-left: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }
}
.tooltipNone {
  display: none;
}

.service-fee {
  margin-bottom: 0px;
}
